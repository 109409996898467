import { Navigation, CasesContainer } from '../components/case-studies/styles'
import { Helmet } from 'react-helmet'

import { SEOCases } from '../components/case-studies/SEOCases'
import { WebCases } from '../components/case-studies/WebCases'
import { AdCases } from '../components/case-studies/AdCases'
import { SocialCases } from '../components/case-studies/SocialCases'

import * as React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'

const tummyContent = [
  '39 yr old, 6 months post-op scars still pink',
  '39 yr old, 6 months post-op scars still pink',
  '39 yr old, 6 months post-op scars still pink',
  '39 yr old, 6 months post-op scars still pink',
  '39 yr old, 6 months post-op scars still pink',
  '36 yr old, 6 months post-op, scars still pink',
  '36 yr old, 6 months post-op, scars still pink',
  '36 yr old, 6 months post-op, scars still pink',
  '36 yr old, 6 months post-op, scars still pink',
  '36 yr old, 6 months post-op, scars still pink',
  '42 yr old, 1 yr post-op',
  '42 yr old, 1 yr post-op',
  '42 yr old, 1 yr post-op',
  '42 yr old, 1 yr post-op',
  '42 yr old, 1 yr post-op',
  '51 yr old, 1 year post-op',
  '51 yr old, 1 year post-op',
  '51 yr old, 1 year post-op',
  '51 yr old, 1 year post-op',
  '51 yr old, 1 year post-op',
  '50 yr old, 6 months post-op. Scar still pink',
  '50 yr old, 6 months post-op. Scar still pink',
  '50 yr old, 6 months post-op. Scar still pink',
  '50 yr old, 6 months post-op. Scar still pink',
  '50 yr old, 6 months post-op. Scar still pink',
  '37 yr old, 1 year post-op',
  '37 yr old, 1 year post-op',
  '37 yr old, 1 year post-op',
  '37 yr old, 1 year post-op',
  '37 yr old, 1 year post-op',
  '26 yr old, 4 months post-op. Scar still pink',
  '26 yr old, 4 months post-op. Scar still pink',
  '26 yr old, 4 months post-op. Scar still pink',
  '26 yr old, 4 months post-op. Scar still pink',
  '26 yr old, 4 months post-op. Scar still pink',
  '40 yr old, 1 year post-op. Slightly stretched scar',
  '40 yr old, 1 year post-op. Slightly stretched scar',
  '40 yr old, 1 year post-op. Slightly stretched scar',
  '40 yr old, 1 year post-op. Slightly stretched scar',
  '40 yr old, 1 year post-op. Slightly stretched scar',
  '60 yr old, 6 weeks post-op. Scar still healing',
  '60 yr old, 6 weeks post-op. Scar still healing',
  '60 yr old, 6 weeks post-op. Scar still healing',
  '60 yr old, 6 weeks post-op. Scar still healing',
  '60 yr old, 6 weeks post-op. Scar still healing',
  '45 yr old 4 months post-op. Scar still pink',
  '45 yr old 4 months post-op. Scar still pink',
  '45 yr old 4 months post-op. Scar still pink',
  '45 yr old 4 months post-op. Scar still pink',
  '45 yr old 4 months post-op. Scar still pink',
  '39 yr old 1 year post-op',
  '39 yr old 1 year post-op',
  '39 yr old 1 year post-op',
  '39 yr old 1 year post-op',
  '39 yr old 1 year post-op',
  '37 yr old 7 weeks post-op. Scar still healing',
  '37 yr old 7 weeks post-op. Scar still healing',
  '37 yr old 7 weeks post-op. Scar still healing',
  '37 yr old 7 weeks post-op. Scar still healing',
  '37 yr old 7 weeks post-op. Scar still healing',
  '54 yr old 3 months post-op. Scar still pink',
  '54 yr old 3 months post-op. Scar still pink',
  '54 yr old 3 months post-op. Scar still pink',
  '54 yr old 3 months post-op. Scar still pink',
  '39 yr old 5 weeks post-op. Scar still healing',
  '39 yr old 5 weeks post-op. Scar still healing',
  '39 yr old 5 weeks post-op. Scar still healing',
  '39 yr old 5 weeks post-op. Scar still healing',
  '48 yr old 6 weeks post-op',
  '48 yr old 6 weeks post-op',
  '48 yr old 6 weeks post-op',
  '48 yr old 6 weeks post-op',
  '48 yr old 6 weeks post-op',
]

const breastContent = [
  '25 yr old, subpectoral 450cc round moderate silicone',
  '25 yr old, subpectoral 450cc round moderate silicone',
  '31 yr old, subpectoral 450cc anatomical, high profile silicone',
  '31 yr old, subpectoral 450cc anatomical, high profile silicone',
  '41 yr old, subpectoral, 325cc round, moderate silicone',
  '41 yr old, subpectoral, 325cc round, moderate silicone',
  '39yr old, subpectoral, 300cc round, moderate silicone',
  '39yr old, subpectoral, 300cc round, moderate silicone',
  '43 yr old, subglandular, 350cc round, moderate silicone',
  '43 yr old, subglandular, 350cc round, moderate silicone',
  '19 year old Subpectoral Anatomic Silicone 375cc MF',
  '19 year old Subpectoral Anatomic Silicone 375cc MF',
  '43 year old Subpectoral Anatomic polyurethane 290cc Nuance short height',
  '43 year old Subpectoral Anatomic polyurethane 290cc Nuance short height',
  '41 year old Subpectoral Anatomic Silicone 400cc MM',
  '41 year old Subpectoral Anatomic Silicone 400cc MM',
  '36 year old Subpectoral Round Moderate Silicone 360cc',
  '36 year old Subpectoral Round Moderate Silicone 360cc',
  '31 year old Subglandular Round Moderate + Profile Silicone Right side 330cc Left 375cc',
  '31 year old Subglandular Round Moderate + Profile Silicone Right side 330cc Left 375cc',
  '23 year old Subpectoral Round Silicone Moderate + 345cc',
  '23 year old Subpectoral Round Silicone Moderate + 345cc',
  '19 year old Subpectoral Round Silicone Moderate + Right 380cc, Left 345cc',
  '19 year old Subpectoral Round Silicone Moderate + Right 380cc, Left 345cc',
  '29 year old Subpectoral Anatomic Silicone 295cc MF',
  '29 year old Subpectoral Anatomic Silicone 295cc MF',
  '44 year old Subpectoral Anatomic Silicone 320cc MM',
  '44 year old Subpectoral Anatomic Silicone 320cc MM',
  '24 year old Subpectoral Round Silicone Moderate + Profile 360cc',
  '24 year old Subpectoral Round Silicone Moderate + Profile 360cc',
  '23 year old Subgladular Anatomical Silicone 410cc MX',
  '23 year old Subgladular Anatomical Silicone 410cc MX',
  '23 year old Subgladular Anatomical Silicone 410cc MX',
  '23 year old Subgladular Anatomical Silicone 410cc MX',
  '35 yr old Subglandular Round Silicone Moderate 350cc',
  '35 yr old Subglandular Round Silicone Moderate 350cc',
  '54 yr old Subpectoral Anatomic Silicone Moderate 315cc',
  '54 yr old Subpectoral Anatomic Silicone Moderate 315cc',
  '43 yr old Subpectoral Round Silicone Moderate 345cc',
  '43 yr old Subpectoral Round Silicone Moderate 345cc',
  '40 yr old Subglandular Round Silicone Moderate 305cc',
  '40 yr old Subglandular Round Silicone Moderate 305cc',
]

const breastReduceContent = [
  '21 yr old, 6 months post-op. Scars still pink',
  '21 yr old, 6 months post-op. Scars still pink',
  '21 yr old, 6 months post-op. Scars still pink',
  '21 yr old, 6 months post-op. Scars still pink',
  '21 yr old, 6 months post-op. Scars still pink',
  '28 yr old, 6 months post-op scars still pink',
  '28 yr old, 6 months post-op scars still pink',
  '70 yr old 12 month post-op',
  '70 yr old 12 month post-op',
  '22 yr old 6 months post-op scars still pink',
  '22 yr old 6 months post-op scars still pink',
  '42 yr old, 6 months post-op, scars still pink',
  '42 yr old, 6 months post-op, scars still pink',
  '60 yr old, 1 yr post-op',
  '60 yr old, 1 yr post-op',
  '59 yr old 3 months post-op. Scars still pink',
  '59 yr old 3 months post-op. Scars still pink',
  '42 yr old, 1 yr post-op',
  '42 yr old, 1 yr post-op',
  '47 year old, 3 months post-op, scars still pink',
  '47 year old, 3 months post-op, scars still pink',
  '42 yr old 12 month post-op',
  '42 yr old 12 month post-op',
  '45 yr old 7 months post-op scars still pink',
  '45 yr old 7 months post-op scars still pink',
  '30 year old, 2 years post-op',
  '30 year old, 2 years post-op',
  '23 year old, 1 year post-op',
  '23 year old, 1 year post-op',
  '63 year old, 2 months post-op, scars still pink',
  '63 year old, 2 months post-op, scars still pink',
  '40 year old, 1 year post-op',
  '40 year old, 1 year post-op',
  '51 year old, 1 year post-op',
  '51 year old, 1 year post-op',
  '37 year old, 6 months post-op',
  '37 year old, 6 months post-op',
  '26 year old, 4 months post-op, scars still pink',
  '26 year old, 4 months post-op, scars still pink',
  '29 year old, 1 year post-op',
  '29 year old, 1 year post-op',
  '49 year old, 5 months post-op, scars still pink',
  '49 year old, 5 months post-op, scars still pink',
  '54 year old, 1 year post-op',
  '54 year old, 1 year post-op',
  '40 year old, 6 weeks post-op, scars still pink',
  '40 year old, 6 weeks post-op, scars still pink',
]

const dermalContent = [
  'Before and 2 weeks after 0.5ml gel filler to each tear trough. Subtle improvement to hollow and shadowing. Product will last 12-18 months.',
  'Before and 2 weeks after 0.5ml gel filler to each tear trough. Subtle improvement to hollow and shadowing. Product will last 12-18 months.',
  'Before and 2 weeks after 0.5ml gel filler to each tear trough. Subtle improvement to hollow and shadowing. Product will last 12-18 months.',
  '2ml filler to Left cheek and 2ml filler to right cheek. Filler replaces volume loss in cheeks and mid face. Product will last 18-24 months.',
  '2ml filler to Left cheek and 2ml filler to right cheek. Filler replaces volume loss in cheeks and mid face. Product will last 18-24 months.',
  '2ml filler to Left cheek and 2ml filler to right cheek. Filler replaces volume loss in cheeks and mid face. Product will last 18-24 months.',
  'Dermal filler to Tear Trough and mid-face',
  'Dermal filler to Tear Trough and mid-face',
  'Dermal filler to smile lines',
  'Dermal filler to smile lines',
  'Dermal filler to lips',
  'Dermal filler to lips',
  'Dermal filler to lips',
  'Dermal filler to lips',
  'Dermal filler to lips',
  'Dermal filler to lips',
  'Dermal filler to lips',
  'Dermal filler to smile lines.',
  'Dermal filler to smile lines',
  'Dermal filler to smile lines',
]
const iplContent = [
  'Before & after',
  'Before and After IPL to full face for pigmentation',
  'Before and After IPL to full face for pigmentation',
  'Before and After IPL to full face for pigmentation',
  'Before and After IPL to full face for pigmentation',
  'Before and After IPL to full face for pigmentation',
  'Before and After IPL to full face for pigmentation',
  'IPL to cheeks for rosacea',
  'IPL to cheeks for rosacea',
  'IPL to full face for sun damage and pigmentation',
  'IPL to full face for sun damage and pigmentation',
]

const coolsculptingContent = [
  'Before and 12 weeks after 1st session to lower abdomen',
  'Before and 12 weeks after 1st session to lower abdomen',
  'Before and 12 weeks after 1st session to lower abdomen',
  'Before and 12 weeks after 1st session to lower abdomen',
  'Before and 12 weeks after 1st session to lower abdomen',
  'Before and 12 weeks after 1st session to lower abdomen',

  'Before and 18 weeks after treatment to rear flanks (love handles)',
  'Before and 18 weeks after treatment to rear flanks (love handles)',
  'Before and 18 weeks after treatment to rear flanks (love handles)',
  'Before and 18 weeks after treatment to rear flanks (love handles)',

  'Before and 12 weeks after 1st session to inner thighs',
  'Before and 12 weeks after 1st session to inner thighs',
  'Before and 12 weeks after 1st session to inner thighs',

  'Before and 18 weeks after first session to abdomen',
  'Before and 18 weeks after first session to abdomen',
  'Before and 18 weeks after first session to abdomen',
  'Before and 18 weeks after first session to abdomen',
  'Before and 18 weeks after first session to abdomen',
  'Before and 18 weeks after first session to abdomen',
  'Before and 18 weeks after 1st session to abdomen',
  'Before and 18 weeks after 1st session to abdomen',
  'Before and 18 weeks after 1st session to abdomen',
  'Before and 18 weeks after 1st session to abdomen',

  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',

  'Before and 6 weeks After 1st session to abdomen',
  'Before and 6 weeks After 1st session to abdomen',
  'Before and 6 weeks After 1st session to abdomen',

  'Before and 6 weeks after 2nd session to rear flanks (muffin top)',
  'Before and 6 weeks after 2nd session to rear flanks (muffin top)',
  'Before and 6 weeks after 2nd session to rear flanks (muffin top)',
  'Before and 6 weeks after 2nd session to rear flanks (muffin top)',
  'Before and 6 weeks after 2nd session to rear flanks (muffin top)',

  'Before and 12 weeks after 2nd session to abdomen',
  'Before and 12 weeks after 2nd session to abdomen',
  'Before and 12 weeks after 2nd session to abdomen',
  'Before and 12 weeks after 2nd session to abdomen',
  'Before and 12 weeks after 2nd session to abdomen',

  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',

  'Before and 12 weeks after first session to flanks (muffin top)',
  'Before and 12 weeks after first session to flanks (muffin top)',
  'Before and 12 weeks after first session to flanks (muffin top)',
  'Before and 12 weeks after first session to flanks (muffin top)',
  'Before and 12 weeks after first session to flanks (muffin top)',
  'Before and 12 weeks after first session to flanks (muffin top)',

  'Before and 12 weeks after 2nd session to flanks (muffin top) and upper back',
  'Before and 12 weeks after 2nd session to flanks (muffin top) and upper back',
  'Before and 12 weeks after 2nd session to flanks (muffin top) and upper back',

  'Before and 12 weeks after 2nd session for abdomen and upper back fat',
  'Before and 12 weeks after 2nd session for abdomen and upper back fat',
  'Before and 12 weeks after 2nd session for abdomen and upper back fat',
  'Before and 12 weeks after 2nd session for abdomen and upper back fat',
  'Before and 12 weeks after 2nd session for abdomen and upper back fat',
  'Before and 12 weeks after 2nd session for abdomen and upper back fat',
  'Before and 12 weeks after 2nd session for abdomen and upper back fat',
  'Before and 12 weeks after 2nd session for abdomen and upper back fat',

  // 'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',
  'Before and 12 weeks after 1st session to abdomen',

  'Before and 10 weeks After Coolsculpting to Neck',
  'Before and 10 weeks After Coolsculpting to Neck',
  'Before and 10 weeks After Coolsculpting to Neck',
  'Before and 10 weeks After Coolsculpting to Neck',
]

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Before &amp; After Photos - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="Check our some of our previous plastic surgery and non-surgical before and after shots."
      />
    </Helmet>
    <Header>
      <h1>Plastic Surgery Sydney Gallery</h1>
      <h4 style={{ color: '#C33F83', maxWidth: 650, margin: '16px auto 0' }}>
        Below is a sample of before and after photos from some of our patients.
        You will be shown more photos in consultation.
      </h4>
    </Header>
    <Navigation>
      <Link to="#breast-enlargement">Breast Augmentation</Link>
      <Link to="#breast-reduction">Breast Reduction</Link>
      <Link to="#ear">Ear Correction</Link>
      <Link to="#eyelid">Eyelid</Link>
      <Link to="#nose">Rhinoplasty</Link>
      <Link to="#tummy-tuck">Abdominoplasty (Tummy Tuck)</Link>
      {/* <Link to="#coolsculpting">CoolSculpting</Link> */}
      {/* <Link to="#dermal">Treatments for Wrinkles and Volume Loss</Link> */}
      {/* <Link to="#ipl">IPL &amp; Laser</Link> */}
    </Navigation>
    <Flex style={{ marginTop: 40, marginBottom: 50 }} justifyContent="center" id="breast-enlargement">
      <h2>Breast Enlargement &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.breastGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp == null ||
            y.node.childImageSharp == null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map(
          (image, i) =>
            image.node.childImageSharp && (
              <GalleryImage modal={image.node.childImageSharp.modal}>
                <Img fluid={image.node.childImageSharp.fluid} />
                <span>{breastContent[i]}</span>
              </GalleryImage>
            )
        )}
    </GalleryContainer>
    <Flex style={{ marginTop: 20, marginBottom: 50 }} justifyContent="center" id="breast-reduction">
      <h2>Breast Reduction &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.breastReduceGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp == null ||
            y.node.childImageSharp == null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map(
          (image, i) =>
            image.node.childImageSharp && (
              <GalleryImage modal={image.node.childImageSharp.modal}>
                <Img fluid={image.node.childImageSharp.fluid} />
                <span>{breastReduceContent[i]}</span>
              </GalleryImage>
            )
        )}
    </GalleryContainer>
    <Flex style={{ marginTop: 20, marginBottom: 50 }} justifyContent="center" id="ear">
      <h2>Ear Correction &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.earGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fluid={image.node.childImageSharp.fluid} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>
    <Flex style={{ marginTop: 20, marginBottom: 50 }} justifyContent="center" id="eyelid">
      <h2>Eyelid Surgery &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.eyelidGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fluid={image.node.childImageSharp.fluid} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>
    <Flex style={{ marginTop: 20, marginBottom: 50 }} justifyContent="center" id="nose">
      <h2>Rhinoplasty (Nose Surgery) &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.noseGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map((image) => {
          if (image.node.childImageSharp === null) {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fluid={image.node.childImageSharp.fluid} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>
    <Flex style={{ marginTop: 20, marginBottom: 50 }} justifyContent="center" id="tummy-tuck">
      <h2>Abdominoplasty (Tummy Tuck) &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.tummyGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return null
          }
          return (
            image.node.childImageSharp && (
              <GalleryImage modal={image.node.childImageSharp.modal}>
                <Img fluid={image.node.childImageSharp.fluid} />
                <span>{tummyContent[i]}</span>
              </GalleryImage>
            )
          )
        })}
    </GalleryContainer>
    {/* <Flex style={{marginTop: 20, marginBottom:50}} justifyContent="center" id="coolsculpting">
      <h2>CoolSculpting &mdash; Gallery</h2>
    </Flex> */}
    {/* <GalleryContainer>
      {data.coolSculptingGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fluid={image.node.childImageSharp.fluid} />
              <span>{coolsculptingContent[i]}</span>
            </GalleryImage>
          )
        })}
    </GalleryContainer> */}
    {/* <Flex style={{ marginTop: 20, marginBottom: 50 }} justifyContent="center" id="dermal">
      <h2>Treatments for Wrinkles and Volume Loss &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.dermalGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fluid={image.node.childImageSharp.fluid} />
              <span>{dermalContent[i]}</span>
            </GalleryImage>
          )
        })}
    </GalleryContainer> */}
    {/* <Flex style={{marginTop: 20, marginBottom:50}} justifyContent="center" id="ipl">
      <h2>IPL &amp; Laser &mdash; Gallery</h2>
    </Flex>
    <GalleryContainer>
      {data.iplGallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          return x.node.childImageSharp.fluid.src.split('/').pop().localeCompare(
            y.node.childImageSharp.fluid.src.split('/').pop()
          )
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fluid={image.node.childImageSharp.fluid} />
              <span>{iplContent[i]}</span>
            </GalleryImage>
          )
        })}
    </GalleryContainer> */}
  </Layout>
)

export const query = graphql`
  query GalleryQuery {
    tummyGallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/tummy-tuck" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    breastGallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/breast-augmentation" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    breastReduceGallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/breast-reduction" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    coolSculptingGallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/coolsculpting" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    iplGallery: allFile(filter: { relativeDirectory: { eq: "ipl" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    noseGallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/nose" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    earGallery: allFile(filter: { relativeDirectory: { eq: "gallery/ear" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    eyelidGallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/eyelid" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 800, quality: 95, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    dermalGallery: allFile(
      filter: { relativeDirectory: { eq: "dermal-fillers" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            modal: fluid(maxWidth: 1000, quality: 100, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
